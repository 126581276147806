import React from 'react'

export default function BlogHeader({ title, subtitle, date }) {
  return (
    <>
      <h1>{title}</h1>
      <div className="subheader">{subtitle}</div>
      <div className="header-date">{date}</div>
    </>
  )
}
