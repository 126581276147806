import clsx from 'clsx'
import React from 'react'
import AppStoreLightLargeSVG from '../icons/appStoreLightLargeSVG'

export default function BlogBanner({
  title,
  text,
  withBackground,
  picture,
  appStoreLink,
}) {
  return (
    <div className={clsx('banner', { 'banner_with-bg': withBackground })}>
      {picture != null && picture}
      <div className="banner__content">
        <div className="banner__header">
          {title ||
            'Download our voice to text app and transcribe 30 minutes free!'}
        </div>
        <div className="banner__text">
          {text ||
            `You will receive 30 minutes of speech-to-text service when you
            register an account. Download VoxRec App Today!`}
        </div>
        <a href={appStoreLink} target="_blank" rel="noreferrer">
          <AppStoreLightLargeSVG className="app-store-link" />
        </a>
      </div>
    </div>
  )
}
