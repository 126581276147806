import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Drift } from 'react-live-chat-loader'
import BlogBanner from '../../components/BlogBanner'
import BlogHeader from '../../components/BlogHeader'
import LiveChatLayout from '../../components/LiveChatLayout'
import Seo from '../../components/seo'
import BackArrow from '../../icons/back-arrow.svg'

export default function BlogPost({ data }) {
  return (
    <LiveChatLayout>
      <Seo title="Introduction to Live (Real-time) Transcription" />
      <div className="drift-wrapper">
        <Drift icon="B" color="rgb(222, 48, 105)" className="circle" />
      </div>
      <section className="blog-section">
        <div className="blog-content">
          <Link to="/blog" className="back-link">
            <img src={BackArrow} alt="Home" />
          </Link>
          <BlogHeader
            title="Introduction to Live (Real-time) Transcription"
            date="1st October 2021"
          />
          <p>
            Most of our professional life revolves around communication. We
            write meeting minutes, emails and exchange ideas.
          </p>
          <p>
            With busy schedules and hundreds of ideas competing for our minds
            and time, it's easy to skip over crucial pieces of information.
          </p>
          <p>
            For many of you, VoxRec is already helping with the transcription of
            meetings, emails, consultations, interviews, and other tasks where
            information must be captured and actioned.
          </p>
          <p>
            Some haven't adopted speech-to-text technology into their daily
            communications yet, which handicaps their workflow without them
            noticing.
          </p>
          <p>
            For a lot of people, voice communication is a challenge. People with
            disabilities, dyslexia and those who don't speak your native
            language all have a problem understanding what you're saying - which
            shouldn't be the case at all.
          </p>
          <p>
            Today we're expanding our existing speech-to-text service by
            introducing Live Transcription in our app. Your recording is
            captured as usual, but transcription is produced on the screen in
            real-time.
          </p>
          <p>
            We hope that this feature will help our users further, especially
            with inclusion into environments and tasks that would otherwise be
            challenging to participate in.
          </p>
          <BlogBanner
            title="Instant Speech-to-Text Transcription"
            text="See and read what's being said without an interpreter and without wait. Try VoxRec's Live Transcription."
            withBackground
            picture={
              <StaticImage
                src="../../images/blog/blog-live-transcribe.png"
                className="banner__image"
                alt="VoxRec on iOS"
              />
            }
            appStoreLink={data.site.siteMetadata.appStoreLink}
          />
          <h2>Digital Accessibility with Speech-to-Text Transcription</h2>
          <p>
            The World Health Organization estimates the number of hard of
            hearing and deaf people to be around 466 million globally.
          </p>
          <p>
            Governments are pushing now more than ever for equality for
            everyone. Live real-time transcription apps can bring this vision to
            fruition.
          </p>
          <p>
            There are millions of other people who struggle to type or take
            notes. Whether it’s dyslexia, dysgraphia, trauma, or other
            disabilities that restrict hand movements or affect cognition, live
            transcription can help these people to take part in online or
            offline conversations, meetings, and events.
          </p>
          <h2>Live Transcription in International Meetings and Conferences</h2>
          <p>
            We live in a time where most meetings and conferences include people
            from different countries. Live transcription helps professionals in
            international board meetings to hear and see what's under
            discussion. That ensures they focus on topics and ideas rather than
            on trying to understand the language.
          </p>
          <h2>Real-time Captioning in Classes and Lectures</h2>
          <p>
            Lecturers and students could be far more efficient if they
            implemented AI-powered speech recognition technology in their
            lessons.
          </p>
          <p>
            Students with disabilities or not fluent in the spoken language can
            use VoxRec to see what the lecturer is saying in real-time as text.
            That'll help them learn, understand, and respond better to the
            context of the presentation.
          </p>
          <p>
            After the lesson is over, VoxRec will send them an accurate
            transcript via email that they can use as study notes.
          </p>
          <p>
            Similarly, conferences and presentations can use live captioning to
            help elaborate the speaker's words, which is very helpful when
            there's no translator for the deaf and hard of hearing.
          </p>
          <h2>Try Live Transcription in VoxRec</h2>
          <p>
            You can activate and try out the Live Transcription feature on the
            recording screen of the VoxRec app. Tap on the robot face (🤖),
            speak naturally and watch the text appearing on the screen as you
            talk. Magic!
          </p>
          <p>
            You can adjust the font size right on the screen to ensure that the
            text is easy to read.
          </p>
          <p>
            When you stop recording, you'll have both the audio and the
            transcript available for review or export.
          </p>
          <p>
            Note - live transcription cannot be automatically emailed to you
            automatically the same way as when you do a regular cloud
            transcription of a pre-recorded audio.
          </p>
          <p>
            If you’d like to know more, check out our updated{' '}
            <a href="/faq">FAQ on our website</a> or inside the app.
          </p>
          <p>
            Let us know what you think about the new Live Transcription feature
            - all feedback is welcome!
          </p>
        </div>
      </section>
    </LiveChatLayout>
  )
}

export const data = graphql`
  query {
    site {
      siteMetadata {
        appStoreLink
      }
    }
  }
`
